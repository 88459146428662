import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { GameException, UserAccessException } from '@/exceptions';

import { GameError, NotFound, ServerError, UserAccessError } from '../pages';

const Error = () => {
  const error = useRouteError();
  // eslint-disable-next-line no-console
  console.error(error);

  if (error instanceof GameException) {
    return <GameError />;
  }

  if (error instanceof UserAccessException) {
    return <UserAccessError />;
  }

  return isRouteErrorResponse(error) ? <NotFound /> : <ServerError />;
};

export default Error;
